import { Box, Button, ButtonBase, Card, CardContent, CardHeader, Chip, CircularProgress, Divider, FormControl, FormGroup, IconButton, Input, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react"
import Layout from "../components/shared-layout";
import Breadcrumb from "../components/shared-breadcrumb";
import useStores from "../hooks/use-stores"
import VmButton from "../components/shared-button";
import VmModal from "../components/shared-modal";
import VmTable from "../components/shared-table";
import TabPanel from "../components/shared-tab-panel";
import { GetOrgId, PAGE_SIZE, ProdEnvCheck } from "../constants/options";
import ContentLayout from "../components/shared-content-layout";
import { t } from "i18next";
import { TD_FIRST, TD_LAST, TD_NORMAL, THEME_COLOR } from "../constants/style";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { constructImgUrl } from "../constants/settings";





function TabProps(index: number, isActive: boolean) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index} `,
    className: `${isActive ? 'text-red-500' : 'text-black'}`
  };
}




const VisitPage = observer(() => {
  const { visitStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentVisit, setCurrentVisit] = useState<any>();
  const [startDate, setStartDate] = useState<any>(new Date(
    new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState<any>(new Date().toISOString().split('T')[0]);
  const [tabValue, setTabValue] = useState<number>(0);
  const [page, setPage] = useState<number>(1);

  // visit tab: fold / unfold
  const [fold, setFold] = useState<number>();

  // modal for status
  const [statusModal, setStatusModal] = useState<boolean>(false);

  // create visit request prop
  const [createVisitModel, setCreateVisitModel] = useState({
    organistaionId: null,
    startTime: null,
    endTime: null,
  })


  useEffect(() => {
    onToggleVisitList();
    getOrgList();
  }, []);

  const handleShowModal = (id: number) => {
    let target = visitStore.visitListInDateRange.data.find(v => v.id == id);
    setCurrentVisit(target);
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentVisit(null);
    setTabValue(0);
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onToggleVisitList = () => {
    setPage(1);
    visitStore.getOrgVisitListByDateRange({
      take: PAGE_SIZE,
      skip: 0,
      orgId: GetOrgId(),
      startDate,
      endDate,
    });
  }

  //June
  const handleCloseStatusModal = () => {
    setStatusModal(false);
    setTabValue(0);
  }

  const onToggleFold = (id: number) => {
    if (fold === id) {
      setFold(0)
    } else {
      setFold(id)
      onToggleChangeVisitList(id)
    }
  }

  const onToggleChangeVisitList = (id: number) => {
    visitStore.getChangeRequestListByVisitId(id);
  }

  const statusPostHandler = (changeId: number, status: string) => {
    const res = confirm("Are you sure?")
    if (res) {
      if (status === "ACCEPTED") {
        visitStore.updateChangeRequestStatus(changeId, true)
        alert(status)
        window.location.reload()
      } else if (status === "REJECTED") {
        visitStore.updateChangeRequestStatus(changeId, false)
        alert(status)
        window.location.reload()
      } else {
        alert("Error: " + status)
      }
    }
  }

  const getOrgList = () => {
    console.log(localStorage.GroupId)
    visitStore.getOrganisationListbyGrouId(localStorage.GroupId)
    console.log(visitStore.organisationList)
  }

  return (
    <ContentLayout
      pageName={t('VISIT_HISTORY')}
      pageHeading={t('VISIT_HISTORY')}
      breadCrumb={[
        // { label: t('VISIT'), to: "" },
        // { label: t('VISIT_HISTORY'), to: "/visit" }
      ]}
      showHeadingButton={false}
    >
      <div className="grid grid-cols-5 gap-4">
        <TextField
          value={startDate}
          label={t('START_DATE')}
          type="date"
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2, background: 'white' }}
          className="col-span-2"
          onChange={(value) => setStartDate(value.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={endDate}
          label={t('END_DATE')}
          type="date"
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2, background: 'white' }}
          className="col-span-2"
          onChange={(value) => setEndDate(value.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          variant="outlined"
          sx={{ marginBottom: 2 }}
          disabled={new Date(startDate) > new Date(endDate)}
          onClick={() => onToggleVisitList()}
        >
          {t('APPLY')}
        </Button>
      </div>




          {/* Visit List */}
      <VmTable
        loading={visitStore.loading}
        page={page}
        paginationCount={visitStore.visitListInDateRange.totalCount == 0 ? 1 : visitStore.visitListInDateRange.totalCount / PAGE_SIZE}
        onChangePagination={(event, pageNumber) => {
          visitStore.getOrgVisitListByDateRange({
            take: PAGE_SIZE,
            skip: (pageNumber - 1) * PAGE_SIZE,
            orgId: GetOrgId(),
            startDate,
            endDate,
          });
          setPage(pageNumber);
        }}
        thead={["ID", t('VISIT_NAME'), t('START_TIME'), t('END_TIME'), 'Responsible Trainer', 'Conduct Trainer', t('ACTION')]}
        tbody={visitStore.visitListInDateRange.data.length > 0 ?
          visitStore.visitListInDateRange.data.map((visit: any, index: number) => (
            <>
              <Box marginY={1} />
              <tr key={`visit_list_${index}`} style={{ cursor: "pointer" }}>
                <td className={TD_FIRST} onClick={() => onToggleFold(visit.id)}>{index + 1}</td>
                <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.name}</td>
                <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.startTime.split('T')[0] + " " + visit.startTime.split('T')[1]}</td>
                <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.endTime.split('T')[0] + " " + visit.endTime.split('T')[1]}</td>
                <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>{visit.staffName}</td>
                <td className={TD_NORMAL} onClick={() => onToggleFold(visit.id)}>
                  {visit.organisationVisitStudents > 0 ?
                    `${visit.organisationVisitStudents[0].result ?
                      visit.organisationVisitStudents[0].result : 0}/100` : `No Marked`}
                </td>
                <td className={TD_LAST}>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => handleShowModal(visit.id)}
                  >
                    {t('RESCHEDULE')}
                  </Button>
                </td>
              </tr>

              <tr className={fold === visit.id ? "" : "hidden"}>
                {/* folding or unfolding */}
                <td colSpan={7}>
                  <div style={{ backgroundColor: "#e4f5f1", borderRadius: "5px", padding: "20px 40px", display: "flex", gap: "10px", justifyContent: "space-between" }}>
                    <Card style={{ padding: "10px" }}>
                      <CardHeader>

                      </CardHeader>
                      <CardContent style={{textAlign: "center"}}>
                        <Typography><b>Name: </b>{visit.name}</Typography>
                        <Typography><b>Description: </b>{visit.description}</Typography>
                        <Typography><b>Trainer Name: </b>{visit.responsibleTrainerName}</Typography>
                        <Typography><b>StartTime: </b>{visit.startTime.split("T")[0]} {visit.startTime.split("T")[1]}</Typography>
                        <Typography><b>EndTime: </b>{visit.endTime.split("T")[0]} {visit.endTime.split("T")[1]}</Typography>
                        <Typography><b>Student Name: </b>{visit.studentName}</Typography>
                        <Typography><b>Student Email: </b>{visit.studentEmail}</Typography>
                        <Typography><b>Location: </b>{visit.locationName}</Typography>
                        <Typography><b>Traineeship Organisation Name: </b>{visit.traineeshipOrgName}</Typography>
                        <Typography><b> Status: </b>{visit.status}</Typography>
                      </CardContent>
                    </Card>
                    <hr style={{ height: "2px", backgroundColor: "#cedbd8", margin: "10px 0" }} />
                    <Card>
                      <div>
                        <Typography variant="h5" mt={1} mb={1}>Request History</Typography>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow key="">
                                <TableCell>ID</TableCell>
                                <TableCell>Changed By</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Start Time</TableCell>
                                <TableCell>End Time</TableCell>
                                <TableCell>Organisation</TableCell>
                                <TableCell>Trainer</TableCell>
                                <TableCell>Trainee</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {visitStore.visitChangeList.map((visit: any, index: number) => (
                                <TableRow key={index}>
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>{visit.changedBy}</TableCell>
                                  <TableCell>{visit.location.name}</TableCell>
                                  <TableCell>{visit.startTime.split("T")[0]} {visit.startTime.split("T")[1]}</TableCell>
                                  <TableCell>{visit.endTime.split("T")[0]} {visit.endTime.split("T")[1]}</TableCell>
                                  <TableCell>{visit.orgAccept !== null ?
                                    (visit.orgAccept ? <span style={{ color: "green" }}>ACCPEPTED</span> : <span style={{ color: "red" }}>REJECTED</span>)
                                    : <><Button onClick={() => statusPostHandler(visit.id, "ACCEPTED")}>ACCEPT</Button>|<Button color="error" onClick={() => statusPostHandler(visit.id, "REJECTED")}>REJECT</Button></>}</TableCell>
                                  <TableCell>{visit.trainerAccept !== null ? (visit.trainerAccept ? <span style={{ color: "green" }}>ACCPEPTED</span> : <span style={{ color: "red" }}>REJECTED</span>) : <span style={{ color: "grey" }}>PENDING</span>}</TableCell>
                                  <TableCell>{visit.traineeAccept !== null ? (visit.traineeAccept ? <span style={{ color: "green" }}>ACCPEPTED</span> : <span style={{ color: "red" }}>REJECTED</span>) : <span style={{ color: "grey" }}>PENDING</span>}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Card>
                    <hr style={{ height: "2px", backgroundColor: "#cedbd8" }} />
                  </div>
                </td>
              </tr>
              {/* </div> */}
              <Box marginY={1} />
            </>
          )) : <tr><td colSpan={7} className="text-center py-2">{t('NO_HISTORY_FOUND')}</td></tr>}
      />
      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        width={500}
        title={t('Rescedule')}
        showButton={false}
      >
        {currentVisit &&
          <>
            <TabPanel value={tabValue} index={0} paddingValue={0}>
              

              {/* June */}
              {/* Current Visit */}
              <form>
                <FormGroup>
                  <InputLabel htmlFor="Date">Location</InputLabel>
                  <Input style={{ marginBottom: "10px" }} placeholder={currentVisit.locationId}></Input>
                  <InputLabel htmlFor="StartTime">Start Time</InputLabel>
                  <Input style={{ marginBottom: "10px" }} placeholder={`${currentVisit.startTime.split("T")[0]}` + " " + `${currentVisit.startTime.split("T")[1]}`} />
                  <InputLabel htmlFor="EndTime">End Time</InputLabel>
                  <Input style={{ marginBottom: "10px" }} placeholder={`${currentVisit.endTime.split("T")[0]}` + " " + `${currentVisit.endTime.split("T")[1]}`} />
                  <Button variant="outlined"
                    sx={{ margin: 2, float: "right" }}>Submit</Button>
                </FormGroup>
              </form>
            </TabPanel>

            
          </>}
      </VmModal>
      <VmModal
        openModal={statusModal}
        onClose={handleCloseStatusModal}
        width={600}
        title={t('Status')}
        showButton={false}>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Reschedule Offer</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Organisation</TableCell>
                <TableCell style={{ color: "green" }}>ACCEPTED</TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Trainer</TableCell>
                <TableCell style={{ color: "red" }}>REJECTED</TableCell>
                <TableCell>Start Time: 16:40:00 <br /> End Time: 17:40:00</TableCell>
              </TableRow>
              <TableRow>
                {/* if status == null -> waiting.. grey */}
                <TableCell>Trainee</TableCell>
                <TableCell style={{ color: "grey" }}>Waiting...</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

      </VmModal>

    </ContentLayout>
  )
});

export default VisitPage;
